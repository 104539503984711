<template>
  <section class="main-wrap">
    <header class="inner-page-header">
      <h1 class="big-title">Create your client</h1>
    </header>

    <form action="#" class="create-client-form" @submit="createClient">
      <!-- <p v-if="isSaving">{{ imageProgress }}%</p> -->

      <div v-if="isSaving" class="progress">
        <p class="pourcentage">{{ parseInt(imageProgress) }}<sup>%</sup></p>
        <div class="progress-bar-wrap">
          <div class="progress-bar" :style="'width:' + imageProgress + '%'"></div>
        </div>
      </div>

      <div v-if="!isSaving && client.profileImg" class="input-file-wrap preview-wrap">
        <div class="image-wrapper">
          <label for="file">
            <img v-if="!cropImg" alt="Plus" :src="client.croppedImg ? client.croppedImg : client.profileImg" />
            <img v-if="cropImg" :src="cropImg" alt="Plus" />
          </label>
        </div>
      </div>
      <div v-if="!isSaving && !client.profileImg" class="input-file-wrap">
        <input id="file" type="file" name="file" class="inputfile" accept="image/*" @change="uploadImage($event)" />
        <label for="file">
          <img alt="Plus" src="@/assets/img/input-file-plus.svg" />
        </label>
      </div>
      <div v-else-if="!isSaving && client.profileImg">
        <div for="file" @click.prevent="changeImage()">
          <p class="replace">Replace photo</p>
        </div>
      </div>

      <div v-if="!isSaving && !client.profileImg" class="client-photo-label">
        <p>Client photo</p>
        <div class="info-tip">
          <img src="@/assets/img/info-tip.svg" alt="Info" @mouseleave="mouseLeave" @mouseover="mouseOver" />
          <div class="tip" :class="{ showtip: tipActive }">
            <p>Choose your client's profile picture for social media preview</p>
          </div>
        </div>
      </div>

      <div class="input-label-wrap" :class="{ filled: client.name }">
        <input v-model="client.name" type="text" class="label-focus" required />
        <p class="label">Client name</p>
      </div>
      <!-- <router-link to="/AddPlatform">
        <div class="bt">
          <p>Suivant</p>
          <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
        </div>
      </router-link> -->
      <button type="submit" class="bt" :class="{ 'is-loading': isSaving }">
        <div class="bt-text">
          <p>Next</p>
          <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
        </div>
        <p class="loader">
          &nbsp;
          <svg class="main-loader" width="38" height="38" xmlns="http://www.w3.org/2000/svg" stroke="#EFF1F2">
            <g transform="translate(1 1)" stroke-width="2" fill="none" fill-rule="evenodd">
              <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
              </path>
            </g>
          </svg>
          &nbsp;
        </p>
      </button>
    </form>

    <!-- Cropper Popup -->
    <div :class="['croppper-popup-wrapper', { 'is-active': imgSrc && !shouldClosePopup }]">
      <div class="cropper-popup">
        <a href="#" class="close-action" @click.prevent="closePopup"><img src="@/assets/img/close.svg" alt="close" /></a>
        <vue-cropper
          v-if="imgSrc"
          ref="cropper"
          :aspect-ratio="13 / 13"
          :drag-mode="'move'"
          :movable="true"
          :rotatable="false"
          :crop-box-movable="true"
          :crop-box-resizable="false"
          :responsive="true"
          :src="imgSrc"
          alt="Source Image"
          preview=".preview"
        >
        </vue-cropper>

        <div v-if="imgSrc" class="actions">
          <div class="zoom-wrap">
            <a href="#" @click.prevent="zoomIn">+<small>1</small></a>
            <a href="#" @click.prevent="zoomInTwice">+<small>2</small></a>

            <a href="#" @click.prevent="zoomOut">-<small>1</small></a>
            <a href="#" @click.prevent="zoomOutTwice">-<small>2</small></a>
          </div>
          <a href="#" role="button" class="bt" @click.prevent="cropImage">Crop</a>
        </div>
      </div>
    </div>

    <router-link to="/clients" class="small-outline-bt dark">
      <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
      <p>Back</p>
    </router-link>
  </section>
</template>

<script>
// Cropper
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

import { isNil } from 'lodash'
import { mapState, mapActions } from 'vuex'
import { storageRef, firebase } from '@/firebase/init'
import { doAlert } from '@/misc/helpers'

import AccountclientsDB from '@/firebase/account-clients-db'
import CleanupJobsDB from '@/firebase/cleanup/cleanup-jobs-db'

export default {
  head: function () {
    return {
      title: {
        inner: 'Create a client',
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} create your client`,
          id: 'desc',
        },
      ],
    }
  },

  components: {
    VueCropper,
  },

  data() {
    return {
      client: {
        profileImg: '',
        name: '',
        isArchived: false,
        socialName: {
          facebook: '',
          instagram: '',
          linkedin: '',
        },
      },
      imgSrc: '',
      cropImg: '',
      data: null,
      isSaving: false,
      imageUrl: '',
      imageProgress: 0,
      mode: 'create',
      currentClientImgName: null,
      shouldClosePopup: false,
      tipActive: false,
      error: '',
    }
  },

  computed: {
    clientId() {
      return this.$route.params.clientId
    },

    ...mapState('app', ['appTitle']),
    ...mapState('authentication', ['user']),
    ...mapState('users', ['account', 'accountMembership', 'clients']),
  },

  watch: {
    account: {
      async handler(account) {
        if (!isNil(account)) {
          if (this.$route.params.clientId) {
            this.mode = 'update'
            const accountclientsdbRef = new AccountclientsDB(this.account[0].id)
            this.client = await accountclientsdbRef.read(this.$route.params.clientId)
            return
          }

          this.refreshClientList()
        }
      },
      immediate: true,
    },

    clients: {
      async handler(clients) {
        if (!isNil(clients) && clients.length >= 1 && this.mode !== 'update') {
          if (!isNil(this.accountMembership) && this.accountMembership.id) {
            if (clients.length >= this.accountMembership.config.clients) {
              this.$router.push({ name: 'Clients', query: { membershipGuard: true } })
            }
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    mouseOver() {
      this.tipActive = true
    },

    mouseLeave() {
      this.tipActive = false
    },

    changeImage() {
      // Remember what was the name of the file before removing it...
      this.currentClientImgName = this.client.imageRef
      this.client.profileImg = ''
    },

    closePopup() {
      if (this.shouldClosePopup) {
        this.shouldClosePopup = false
      } else {
        this.shouldClosePopup = true
        this.currentClientImgName = this.client.imageRef
        this.client.profileImg = ''
      }
    },

    zoomIn() {
      this.$refs.cropper.relativeZoom(0.005)
    },

    zoomOut() {
      this.$refs.cropper.relativeZoom(-0.005)
    },

    zoomInTwice() {
      this.$refs.cropper.relativeZoom(0.25)
    },

    zoomOutTwice() {
      this.$refs.cropper.relativeZoom(-0.25)
    },

    async createClient(e) {
      e.preventDefault()

      this.isSaving = true

      // console.log('createClient()', this.client)

      let dbClient = null

      try {
        const accountClientsDbRef = new AccountclientsDB(this.account[0].id)
        dbClient = await accountClientsDbRef[this.mode](this.client)
        this.isSaving = false
      } catch (error) {
        doAlert({ title: error.message, type: 'error' })
        this.isSaving = false
        return
      }

      doAlert({
        title: `Nouveau client créé avec succès`,
        type: 'success',
      })
      this.client = {
        profileImg: '',
        name: '',
        isArchived: false,
        socialName: {
          facebook: '',
          instagram: '',
          linkedin: '',
        },
      }
      // If we are 'creating' we go forward and redirect user to 'campaigns' listing
      // If we are 'editing' we go back to 'clients' isting
      if (this.mode === 'create') {
        this.$router.push({
          name: 'CampaignsList',
          params: { clientId: dbClient.id },
        })
      } else {
        this.$router.push({ name: 'Clients' })
      }
    },

    async uploadImage(event) {
      let fileName = `${new Date().valueOf()}.jpg`

      // If we are Modifying existing image,
      // we must take the same name as before to avoid unused broken images in DB/Storage
      if (this.currentClientImgName) {
        fileName = this.currentClientImgName
      }

      // console.log(`🖼 name === ${fileName}`)
      const uploadTask = storageRef.child(`${this.account[0].id}/clients/${fileName}`).put(event.target.files[0])

      this.isSaving = true
      this.shouldClosePopup = false

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          this.imageProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // console.log('Upload is ' + this.imageProgress + '% done')
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              // console.log('Upload is paused')
              break
            case firebase.storage.TaskState.RUNNING: // or 'running'
              // console.log('Upload is running')
              break
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          // console.log(error)
          this.isSaving = false
          this.error = error
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            // console.log('File available at', downloadURL)
            this.client['profileImg'] = downloadURL
            this.client['imageRef'] = fileName
            this.client['hasThumbs'] = true

            const cleanupJobsDB = new CleanupJobsDB()
            cleanupJobsDB.create({
              type: 'clients',
              accountId: this.account[0].id,
              imageRef: fileName,
              filePath: `${this.account[0].id}/clients/${fileName}`,
            })

            setTimeout(() => {
              this.imgSrc = downloadURL
              // rebuild cropperjs with the updated source
              this.$refs.cropper.replace(downloadURL)
            }, 375)

            this.isSaving = false
          })
        }
      )
    },

    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.6735)
      this.client.croppedImg = this.cropImg
      this.shouldClosePopup = true
    },

    refreshClientList() {
      this.getUserClients()
    },

    ...mapActions('users', ['getUserClients']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typography.scss';
@import '@/theme/button.scss';
@import '@/theme/forms.scss';
@import '@/theme/crop-pop.scss';

.inner-page-header {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 35px;
}

.small-outline-bt {
  position: absolute;
  top: 90px;
  left: 30px;
}

.create-client-form {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  text-align: center;
  position: relative;

  .replace {
    color: $purple-blue;
    cursor: pointer;
  }

  .client-photo-label {
    display: flex;
    justify-content: center;

    .info-tip {
      position: relative;
      img {
        width: 20px;
        opacity: 0.5;
        transition: all 0.2s ease;
        margin-left: 10px;
        &:hover {
          opacity: 1;
        }
      }
      .tip {
        position: absolute;
        top: -40px;
        left: 50%;
        width: 375px;
        transform: translate(calc(-50% + 5px), 0);
        background-color: $dark-grey;
        padding: 10px;
        border-radius: 3px;
        opacity: 0;
        transition: all 0.3s ease;

        &.showtip {
          top: -50px;
          opacity: 1;
        }

        p {
          position: relative;
          z-index: 20;
          margin-bottom: 0;
          color: #fff;
          font-size: 12px;
        }

        &:before {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 2px;
          background-color: $dark-grey;
          position: absolute;
          z-index: 10;
          bottom: -5px;
          left: 50%;
          transform: translate(-50%, 0) rotate(-45deg);
        }
      }
    }
  }

  .progress {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;

    .pourcentage {
      font-family: 'Open Sans', sans-serif;
      font-size: 40px;
      color: $dark-grey;
      font-weight: 300;
      margin-bottom: 0;
      sup {
        font-size: 50%;
        font-weight: 400;
      }
    }

    .progress-bar-wrap {
      width: 100%;
      height: 8px;
      background: #fff;
      border-radius: 20px;

      .progress-bar {
        width: 0%;
        height: 8px;
        background: linear-gradient(to right, #2e7df2, #8a23f1);
        transition: all 0.4s ease;
      }
    }
  }

  p {
    color: $dark-grey;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 35px;
  }

  .bt {
    position: relative;
    margin-top: 35px;
    display: inline-block;
    border: none;
    cursor: pointer;

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
    }

    .loader,
    svg {
      display: none;
    }

    &.is-loading {
      .bt-text {
        display: none;
      }

      .loader {
        display: block;
      }

      svg {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.input-file-wrap {
  display: inline-block;
  margin-bottom: 10px;

  &.preview-wrap .image-wrapper {
    position: relative;
    display: block;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden;

    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 105%;
      height: auto;
    }
  }
}

textarea {
  width: 100%;
  height: 100px;
}
.preview-area {
  width: 307px;
}
.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}
.preview-area p:last-of-type {
  margin-top: 1rem;
}
.preview {
  display: none;
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}
.cropped-image img {
  max-width: 100%;
}
</style>
